import Cookies from 'js-cookie';
import { ParsedUrlQuery } from 'querystring';
import { ADMITAD_COOKIE_NAME, SALESDOUBLER_COOKIE_NAME } from '../../redux/constants';

export const updateAffiliateProgramsCookie = (
  query: ParsedUrlQuery, host: string, WITH_COOKIE_POLICY?: boolean,
) => {
  const topHost = host.split('/')[0].split('.').slice(-2).join('.');
  const cookiesAreAllowed = !WITH_COOKIE_POLICY || Cookies.get('cookiesAllowed') === 'true';

  if (cookiesAreAllowed) {
    if (query.admitad_uid) {
      setAdmitadCookie(query.admitad_uid as string, topHost);
    }

    if (query.aff_sub) {
      setSalesDoublerCookie(query.aff_sub as string, topHost);
    }
  }
};

export const removeAffiliateProgramsCookie = (host: string) => {
  const salesDoublerCookie = Cookies.get(SALESDOUBLER_COOKIE_NAME);

  if (salesDoublerCookie) {
    const topHost = host.split(':')[0].split('.').slice(-2).join('.');
    removeSalesDoublerCookie(topHost);
  }
};

const setAdmitadCookie = (value: string, host: string) => {
  Cookies.set(
    ADMITAD_COOKIE_NAME,
    value,
    {
      expires: 30,
      domain: `.${host}`,
    },
  );
};

const setSalesDoublerCookie = (value: string, host: string) => {
  Cookies.set(
    SALESDOUBLER_COOKIE_NAME,
    value,
    {
      expires: 30,
      domain: `.${host}`,
    },
  );
};

const removeSalesDoublerCookie = (host: string) => {
  Cookies.remove(
    SALESDOUBLER_COOKIE_NAME,
    {
      domain: `.${host}`,
    },
  );
};
