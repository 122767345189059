import { createSelector, Selector } from '@reduxjs/toolkit';
import { DeliveryType } from 'src/redux/apiTypes';
import { DEFAULT_DELIVERY_TYPE } from 'src/redux/constants';
import { RootState } from '../../reducers';
import selectSelectedDelivery from './selectSelectedDelivery';

const selectDeliveryType: Selector<RootState, DeliveryType> = createSelector(
  [selectSelectedDelivery],
  (delivery) => delivery?.type || DEFAULT_DELIVERY_TYPE,
);

export default selectDeliveryType;
