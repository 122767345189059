import { ActionReducerMapBuilder, createAction } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import makeSearchString from "../../utils/system/makeSearchString";
import { Product } from "../apiTypes";
import selectDeliveryType from "../cart/general/selectDeliveryType";
import { RootState } from "../reducers";
import { ProductData, ProductState } from "./types";

const fetchProductDataRequest = createAction('product/fetchProductDataRequest');
const fetchProductDataSuccess = createAction<ProductData>('product/fetchProductDataSuccess');
const fetchProductDataError = createAction('product/fetchProductDataError');

const fetchProductData = (
  storeId: string,
  ean: string | string[],
  includesCount: number,
) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const requestParams = makeSearchString({
    include: `recommended,similar,linked`,
    count: includesCount,
  });

  const state = getState();
  const deliveryType = selectDeliveryType(state);
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  return dispatch(createRsaaAction<RootState, Product[], undefined>({
    method: 'GET',
    endpoint: `${apiRoot}/stores/${storeId}/products/${ean}/` + requestParams,
    headers: {
      'Accept-Language': language,
      'x-chain': xChain,
      'x-version': xVersion,
      'X-Delivery-Type': deliveryType,
    },
    credentials: 'include',
    types: [
      fetchProductDataRequest.type,
      fetchProductDataSuccess.type,
      fetchProductDataError.type,
    ],
  }));
};

export default fetchProductData;

export function addFetchProductData(builder: ActionReducerMapBuilder<ProductState>) {
  builder
    .addCase(fetchProductDataRequest, (state) => {
      state.isFetchingProductData = true;
      state.errorFetchingProductData = false;
    })
    .addCase(fetchProductDataSuccess, (state, action) => {
      state.productData = action.payload;
      state.isFetchingProductData = false;
    })
    .addCase(fetchProductDataError, (state) => {
      state.errorFetchingProductData = true;
      state.isFetchingProductData = false;
    });
}
