import Cookies from 'js-cookie';
import { ServerCartDelivery } from 'src/redux/cart/serverCart/types';
import { IS_SERVER } from "../../redux/constants";
import hasAddressCoords from './hasAddressCoords';

export const ADDRESS_LOCAL_STORAGE_KEY = 'address_ui';

export function setAddressInLocalStorage(address?: ServerCartDelivery) {
  if (!IS_SERVER) {
    if (address) {
      localStorage?.setItem(ADDRESS_LOCAL_STORAGE_KEY, JSON.stringify(address));
    } else {
      localStorage?.removeItem(ADDRESS_LOCAL_STORAGE_KEY);
    }
  }
}

export function getAddressFromLocalStorage() {
  const addressJson = !IS_SERVER
    ? localStorage?.getItem(ADDRESS_LOCAL_STORAGE_KEY)
    : undefined;

  const address: ServerCartDelivery | null = addressJson
  ? JSON.parse(addressJson)
  : null;

  if (!hasAddressCoords(address)) {
    localStorage?.removeItem(ADDRESS_LOCAL_STORAGE_KEY);
    Cookies.remove('storeId');
    Cookies.remove('deliveryType');
    return null;
  }

  return address;
}
