import { useContext } from 'react';
import TogglesContext from 'src/services/toggles/togglesContext';
import { Toggles } from './types';

export default function useToggles(): Toggles {
  const context = useContext(TogglesContext);

  if (!context) {
    throw new Error('Toggles is used without provider');
  }

  return context;
}
