import { AddressDelivery } from 'src/redux/addressManagement/types';
import { CartType } from 'src/redux/app/appReducers';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import useSWR from 'swr';
import { ApiErrorResponse } from '../../redux/apiTypes';
import { CartItem } from '../../redux/cart/general/cartTypes';
import getScheduleDeliveryRequestBodyForClientCart from './getScheduleDeliveryRequestBodyForClientCart';
import { ScheduleDeliveryApi, ScheduleDeliveryList } from './scheduleDeliveryTypes';

export interface UseScheduleDeliveryProps {
  cartType: CartType;
  storeId: string;
  delivery: AddressDelivery;
  cartItems: CartItem[];
  cartETag: string | null;
}

let cachedScheduleDeliveryList: ScheduleDeliveryList = [];
const emptyScheduleDeliveryList: ScheduleDeliveryList = [];

const useScheduleDelivery = (props: UseScheduleDeliveryProps): ScheduleDeliveryApi => {
  // the cache is needed because when the swr key changes,
  // the date value is reset and the UI is twitched.
  // In order for cached data not to be passed from test to test,
  // I disable caching in the test environment.
  const withCache = process.env.NODE_ENV !== 'test';

  const { fetchConfig } = useAppConfig();
  const { xChain, xVersion, apiRoot } = fetchConfig;

  const {
    cartType,
    storeId,
    delivery,
    cartItems,
    cartETag,
  } = props;

  const getSwrKey = () => {
    if (cartType === 'server') {
      return [
        `${apiRoot}/user/delivery_schedule/?slot_types=open,item_specific`,
        'GET',
        undefined,
        cartETag,
      ];
    }

    if (cartType === 'client') {
      const requestBody = getScheduleDeliveryRequestBodyForClientCart(delivery, cartItems);

      return [
        `${apiRoot}/stores/${storeId }/delivery_schedule/with_discount/?slot_types=open,item_specific`,
        'POST',
        JSON.stringify(requestBody),
      ];
    }

    return null;
  };

  const {
    data,
    isValidating: isLoading,
  } = useSWR<ScheduleDeliveryList | ApiErrorResponse>(getSwrKey, fetchScheduleDelivery, { fallbackData: null });

  let scheduleDeliveryList: ScheduleDeliveryList;

  if(withCache) {
    if(Array.isArray(data)) {
      cachedScheduleDeliveryList = data;
    }

    scheduleDeliveryList = cachedScheduleDeliveryList;
  } else {
    scheduleDeliveryList = (!data || ('errors' in data)) ? emptyScheduleDeliveryList : data;
  }

  const error = (data && 'errors' in data) ? data.errors[0]: null;

  const wasLoaded = !!data && !isLoading;

  return {
    scheduleDeliveryList,
    isLoading,
    wasLoaded,
    error,
  };

  function fetchScheduleDelivery(url, method, body) {
    return fetch(url, {
      method,
      body,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'x-chain': xChain,
        'x-version': xVersion,
      },
    }).then(resp => resp.json());
  }
};

export default useScheduleDelivery;
