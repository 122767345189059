import { PayloadAction } from "@reduxjs/toolkit";
import { Category, CategoryProducts } from "../apiTypes";
import createReducer from '../createReducer';
import { END_PREPARE_CATEGORY, FETCH_CATEGORY_DATA_ERROR, FETCH_CATEGORY_DATA_REQUEST, FETCH_CATEGORY_DATA_SUCCESS, FETCH_LOAD_MORE_CATEGORY_SUCCESS, GET_CATEGORY_ERROR, GET_CATEGORY_SUCCESS, RESET_CATEGORY_DATA, SET_CATEGORY_IS_UNAVAILABLE_FOR_NP, START_PREPARE_CATEGORY } from "./catalogueTypes";

export interface CatalogueReducerState {
  selectedCategory: Category | null;
  categoryData: CategoryProducts | null;
  isFetchingCategory: boolean;
  errorFetchingCategory: Response | null;
  isFetchingCategoryData: boolean;
  isNotAvailableForNP: boolean;
}

export const catalogueInitialState: CatalogueReducerState = {
  selectedCategory: null,
  categoryData: null,
  isFetchingCategory: false,
  isFetchingCategoryData: false,
  errorFetchingCategory: null,
  isNotAvailableForNP: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ActionHandlersTypes = Record<string, (state: CatalogueReducerState, action: PayloadAction<any>) => CatalogueReducerState>

const actionHandlers: ActionHandlersTypes = {
  [START_PREPARE_CATEGORY]: state => {
    return {
      ...state,
      isFetchingCategory: true,
      errorFetchingCategory: null,
      isNotAvailableForNP: false,
    };
  },
  [END_PREPARE_CATEGORY]: state => {
    return {
      ...state,
      isFetchingCategory: false,
    };
  },
  [FETCH_LOAD_MORE_CATEGORY_SUCCESS]: (state, action: PayloadAction<CategoryProducts>) => {
    const { payload: categoryData } = action;
    return {
      ...state,
      categoryData: {
        ...categoryData,
        results: [
          ...state.categoryData.results,
          ...categoryData.results,
        ],
      },
      isFetchingCategoryData: false,
    };
  },
  [FETCH_CATEGORY_DATA_REQUEST]: state => {
    return {
      ...state,
      isFetchingCategoryData: true,
    };
  },
  [FETCH_CATEGORY_DATA_SUCCESS]: (state, action: PayloadAction<CategoryProducts>) => {
    const { payload: categoryData } = action;
    return {
      ...state,
      categoryData,
      isFetchingCategoryData: false,
    };
  },
  [FETCH_CATEGORY_DATA_ERROR]: (state, action) => {
    return {
      ...state,
      errorFetchingCategory: action.payload,
    };
  },
  [RESET_CATEGORY_DATA]: state => {
    return {
      ...state,
      categoryData: null,
      selectedCategory: null,
    };
  },
  [GET_CATEGORY_SUCCESS]: (state, action: PayloadAction<Category>) => {
    const category = action.payload;
    return {
      ...state,
      selectedCategory: category,
    };
  },
  [GET_CATEGORY_ERROR]: (state, action) => {
    return {
      ...state,
      errorFetchingCategory: action.payload,
      selectedCategory: null,
    };
  },
  [SET_CATEGORY_IS_UNAVAILABLE_FOR_NP]: (state, action: PayloadAction<boolean>) => {
    return {
      ...state,
      isNotAvailableForNP: action.payload,
    };
  },
};

const reducer = createReducer(
  catalogueInitialState,
  actionHandlers,
);

export default reducer;
