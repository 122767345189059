import { createContext, ReactNode, useContext } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { Toggles } from 'src/services/toggles/types';
import DeliveryPresets from './deliveryPresets/deliveryPresetsContext';
import useDeliveryPresetsService from './deliveryPresets/useDeliveryPresetsService';
import useFavoritesService, { FavoritesService } from './favorites/useFavoritesService';
import { LocalizeFunc, TranslationDictionary } from './localization/types';
import { useLocalizationService } from './localization/useLocalizationService';
import TogglesContext from './toggles/togglesContext';
import useTogglesService from './toggles/useTogglesService';

export interface Services {
  favorites: FavoritesService;
  localization: LocalizeFunc;
}

interface ServicesProviderProps {
  toggles: Toggles;
  children: ReactNode;
  translations: TranslationDictionary;
}

export const ServicesContext = createContext<Services>(null);

export const useServices = () => useContext(ServicesContext);

export const ServicesProvider = ({
  toggles,
  children,
  translations,
}: ServicesProviderProps) => {
  const isLoggedIn = useAppSelector((s) => s.user.isLoggedIn);
  const favorites = useFavoritesService(isLoggedIn);
  const localization = useLocalizationService(translations);
  const deliveryPresets = useDeliveryPresetsService(isLoggedIn);
  const togglesState = useTogglesService(toggles);

  const contextValue = {
    favorites,
    localization,
  };

  return (
    <ServicesContext.Provider value={contextValue}>
      <DeliveryPresets.Provider value={deliveryPresets}>
        <TogglesContext.Provider value={togglesState}>
          {children}
        </TogglesContext.Provider>
      </DeliveryPresets.Provider>
    </ServicesContext.Provider>
  );
};
