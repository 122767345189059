import { NextPageContext } from 'next';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import { AnyAction, applyMiddleware, createStore, Store } from "redux";
import { apiMiddleware } from 'redux-api-middleware';
import { composeWithDevTools } from "redux-devtools-extension";
import thunk, { ThunkAction } from 'redux-thunk';
import rootReducer, { RootState } from '../redux/reducers';
import apiCacheMiddleware from './apiCacheMiddleware';
import deliveryRefreshMiddleware from "./cart/deliveryRefreshMiddleware";
import requestErrorMiddleware from './errorHandlers/requestErrorMiddleware';
import unauthorizedErrorMiddleware from "./errorHandlers/unauthorizedErrorMiddleware";
import unavailableStoreMiddleware from './errorHandlers/unavailableStoreMiddleware';
import unexpectedErrorMiddleware from "./errorHandlers/unexpectedErrorMiddleware";
import userAgentMiddleware from './userAgentMiddleware';


declare module 'redux' {
  /*
   * Overload to add redux-thunk middleware support to Redux's dispatch() function.
   * Useful for react-redux or any other library which could use this type.
   */
  interface Dispatch {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <R, S, E, A extends Action<any>>(action: ThunkAction<R, S, E, A>): R;
  }
}

export type AppStore = ReturnType<typeof initStore>;

export interface NextPageContextWithStore extends NextPageContext {
  store: AppStore
}

const middleware = [
  thunk,
  apiCacheMiddleware,
  userAgentMiddleware,
  apiMiddleware,
  requestErrorMiddleware,
  unauthorizedErrorMiddleware,
  unexpectedErrorMiddleware,
  unavailableStoreMiddleware,
  deliveryRefreshMiddleware,
];

export const reducer = (state: RootState, action: AnyAction): RootState => {
  if (action.type === HYDRATE) {
    if(action.payload.app.reduxType === 'client' || state.app.stateHasHydrated) {
      return {
        ...action.payload,
        ...state,
      };
    } else{
      return {
        ...state,
        ...action.payload,
      };
    }
  } else {
    return rootReducer(state, action);
  }
};

const initStore = () => {
  return createStore(reducer, composeWithDevTools({
    trace: false,
    traceLimit: 50,
  })(applyMiddleware(...middleware)));
};

export const wrapper = createWrapper<Store<RootState>>(initStore);
