import { ShoppingList, EanFavoriteListMap } from "../types";

export const createEanListMap = (lists: ShoppingList[]): EanFavoriteListMap => {
  return lists.reduce((map, { id, items }) => {
    items.forEach(
      ({ ean }) => (map[ean] = map[ean] ? [...map[ean], id] : [id]),
    );
    return map;
  }, {});
};
