import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useUserAgent } from 'src/modules/user-agent';
import setSelectedAddress from 'src/redux/addressManagement/setSelectedAddress';
import { setCartType } from 'src/redux/app/appActions';
import { setCartItems } from 'src/redux/cart/general/operations/setCartItems';
import requestGetCart from 'src/redux/cart/serverCart/requestGetCart';
import { useAppSelector } from 'src/redux/hooks';
import { closeModal } from 'src/redux/modals';
import { setConfirmedAge } from 'src/redux/userProfile/userProfileActions';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { ADDRESS_LOCAL_STORAGE_KEY } from 'src/utils/delivery/addressLocalStorage';
import { getCheckedAddress } from 'src/utils/delivery/getCheckedAddress';
import setDeliveryTypeCookie from 'src/utils/delivery/setDeliveryTypeCookie';
import setStoreIdCookie from 'src/utils/delivery/setStoreIdCookie';
import { checkUserLogin } from '../../../redux/user/userActions';

export default function AppEventsHandlers() {
  const { isIE } = useUserAgent();
  const dispatch = useDispatch();
  const { fetchConfig } = useAppConfig();

  const cartType = useAppSelector((s) => s.app.cartType);
  const serverCartStatusMap = useAppSelector((s) => s.serverCart.statusMap);
  const isLoggedIn = useAppSelector(s => s.user.isLoggedIn);
  const isLoggedInRef = useRef<boolean>(null);
  isLoggedInRef.current = isLoggedIn;

  useEffect(() => {
    const handlePopstate: WindowEventHandlers['onpopstate'] = (event) => {
      if (!event.state || !event.state.productCartOpen) {
        dispatch(closeModal('productCardModal'));
      }
    };

    const handleStorage: WindowEventHandlers['onstorage'] = async (event) => {
      // authEvent for sync tabs when user trigger login/register/logout action
      if (event.key === 'authEvent') {
        if (event.newValue) {
          const newCartType = event.newValue === 'logout' ? 'client' : 'server';

          dispatch(setCartType(newCartType));
          window.location.reload();
        }

        return;
      }

      if (event.key === 'confirmedAge') {
        dispatch(setConfirmedAge(event.newValue));
      }

      if(cartType !== 'client') {
        return;
      }

      if (event.key === 'cart') {
        const cartItems = event.newValue ? JSON.parse(event.newValue) : [];
        dispatch(setCartItems(cartItems, true));
      }

      if (event.key === ADDRESS_LOCAL_STORAGE_KEY) {
        const { oldValue, newValue } = event;
        const oldStorageData = oldValue ? JSON.parse(oldValue) : null;
        const newStorageData = newValue ? JSON.parse(newValue) : null;

        if(newStorageData) {
          const { store: newStore } = await getCheckedAddress(newStorageData, fetchConfig);
          const newStoreId = newStore.id;

          if (!oldStorageData) {
            setDeliveryTypeCookie(newStorageData.delivery);
            setStoreIdCookie(newStoreId);
            window.location.reload();
          } else {
            const { store: oldStore } = await getCheckedAddress(oldStorageData, fetchConfig);

            const oldStoreId = oldStore.id;
            const oldDeliveryType = newStorageData.delivery;
            const newDeliveryType = oldStorageData.delivery;

            // same store but different delivery method
            if (oldStoreId === newStoreId && oldDeliveryType !== newDeliveryType) {
              setDeliveryTypeCookie(newStorageData.delivery);
              window.location.reload();

              // different stores
            } else if (oldStoreId !== newStoreId) {
              setDeliveryTypeCookie(newStorageData.delivery);
              setStoreIdCookie(newStoreId);
              window.location.reload();
            } else {
              dispatch(setSelectedAddress(newStorageData));
            }
          }
        }
      }
    };

    const handleVisibilityChange = async () => {
      if (!document.hidden) {
        const oldIsLoggedIn = isLoggedInRef.current;
        const res: Response | undefined = await dispatch(checkUserLogin());

        /*
        * If an exception occurs at the time of the request
        * (for example, the Internet is gone),
        * the resp variable does not contain a value.
        * In this case, we do not take any action.
        * */
        if(res) {
          if (res.ok !== oldIsLoggedIn) {
            window.location.reload();
          } else {
            const serverCartIsLoading = Object.values(serverCartStatusMap).some((status) => status === 'loading');

            if(cartType === 'server' && !serverCartIsLoading) {
              dispatch(requestGetCart());
            }
          }
        }

      }
    };

    !isIE && window.addEventListener('storage', handleStorage);
    window.addEventListener('popstate', handlePopstate);
    window.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      !isIE && window.removeEventListener('storage', handleStorage);
      window.removeEventListener('popstate', handlePopstate);
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [cartType, dispatch, serverCartStatusMap, isIE, fetchConfig]);

  return null;
}
