import { AddressDelivery } from "src/redux/addressManagement/types";
import { convertCoordsStringToCoordsNumber } from "./convertCoords";

export const getCoordsFromDelivery = (delivery: AddressDelivery) => {
  const deliveryType = delivery?.type;

  if (!delivery || !delivery?.address?.[deliveryType]) {
    return null;
  }

  switch (deliveryType) {
    case 'nova_poshta_address':
      return convertCoordsStringToCoordsNumber(
        delivery.address.nova_poshta_address.address.settlement?.coords,
      );
    case 'nova_poshta':
      return convertCoordsStringToCoordsNumber(
        delivery.address.nova_poshta.warehouse.coords,
      );
    case 'nova_poshta_fresh':
      return convertCoordsStringToCoordsNumber(
        delivery.address.nova_poshta_fresh.settlement.coords,
      );
    case 'plan':
      return delivery.address.plan.coords;
    case 'pickup':
      return delivery.address.pickup.store.address.coords;
    default:
      return null;
  }
};

function hasAddressCoords(delivery: AddressDelivery) {
  const coords = getCoordsFromDelivery(delivery);

  return !!coords;
}

export default hasAddressCoords;
