import * as Sentry from '@sentry/nextjs';
import { AddressDelivery } from 'src/redux/addressManagement/types';
import { getCartPrice } from 'src/redux/cart/clientCart/operations/getCartPrice';
import { DEFAULT_DELIVERY_TYPE } from 'src/redux/constants';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { getAddressFromLocalStorage } from 'src/utils/delivery/addressLocalStorage';
import { getLocalCart } from '../../../utils/cart/saveCart';
import { getCheckedAddress } from '../../../utils/delivery/getCheckedAddress';
import setDeliveryTypeCookie from '../../../utils/delivery/setDeliveryTypeCookie';
import setStoreIdCookie from '../../../utils/delivery/setStoreIdCookie';
import { setAddress, setAddressIsChecked } from '../../addressManagement/addressManagement';
import { DeliveryType } from '../../apiTypes';
import { setCartItems } from '../../cart/general/operations/setCartItems';
import { RootState } from '../../reducers';
import { INIT_CLIENT_CART } from '../appTypes';
import { fetchGeneralAppData } from './fetchGeneralAppData';

export const initClientCartAndDelivery = () => async (
  dispatch,
  getState,
): Promise<boolean> => {
  const state: RootState = getState();
  const { fetchConfig } = selectAppConfig(state);
  const ssrStoreId = state.storeManagement.store.id;

  const ssrDeliveryType: DeliveryType = state.addressManagement.delivery?.type || DEFAULT_DELIVERY_TYPE;
  const localAddress = getAddressFromLocalStorage();
  const { serverCartDelivery, store } = await getCheckedAddress(localAddress, fetchConfig);
  const userHasSelectedAddress = !!serverCartDelivery;

  let addressForSetting: AddressDelivery;

  if (userHasSelectedAddress) {
    addressForSetting = serverCartDelivery;
    const deliveryTypeForSetting = addressForSetting.type;
    const storeIdForSetting = store?.id;
    const deliveryMethodsAreDifferent =
      deliveryTypeForSetting !== ssrDeliveryType ||
      storeIdForSetting !== ssrStoreId;

    // StoreId sentry report
    if (!storeIdForSetting || storeIdForSetting === 'undefined') {
      Sentry.captureException(
        new Error('StoreId is undefined'),
        {
          extra: {
            where: 'initClientCartAndDelivery.ts',
            cartType: state.app?.cartType,
            deliveryType: state.serverCart?.data?.delivery?.type,
            address: JSON.stringify(addressForSetting),
          },
        },
      );
    }

    setStoreIdCookie(storeIdForSetting);
    setDeliveryTypeCookie(deliveryTypeForSetting);

    if (deliveryMethodsAreDifferent) {
      // Reloading the page is necessary to update the data loaded on the SSR,
      // which depends on the values of the storeId and deliveryType
      window.location.reload();

      return false;
    }
  } else {
    addressForSetting = null;
  }

  dispatch(setAddress(addressForSetting));
  dispatch(setAddressIsChecked());

  await dispatch(fetchGeneralAppData());

  const localItems = getLocalCart();

  dispatch(setCartItems(localItems, true));
  dispatch({ type: INIT_CLIENT_CART });

  await dispatch(getCartPrice(true));

  return true;
};
