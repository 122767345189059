import { Dispatch } from 'redux';
import { DeliveryType } from 'src/redux/apiTypes';
import { RootState } from 'src/redux/reducers';
import { Language } from 'src/utils/appConfig/getInitialAppConfig';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import setSelectedAddress from '../../addressManagement/setSelectedAddress';
import requestGetRetailChain from '../../retailChain/requestGetRetailChain';
import { getServicePages } from '../../servicePages/servicePagesActions';
import requestGetStoreById from '../../storeManagement/requestGetStoreById';
import { getTranslations } from '../../translations/translationsActions';
import { setBaseURL, setLanguage } from '../appActions';

interface Params {
  preferredLanguage: Language;
  storeId: string;
  baseURL: string;
  deliveryType?: DeliveryType;
  host?: string;
}

export const initAppOnServerSide = (params: Params) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const {
    preferredLanguage,
    storeId,
    baseURL,
    deliveryType = null,
    host,
  } = params;

  const state = getState();
  const { languages, defaultLanguage } = selectAppConfig(state);

  const language = languages.includes(preferredLanguage)
    ? preferredLanguage
    : defaultLanguage;

  dispatch(setLanguage(language));

  const res = await dispatch(requestGetStoreById(storeId));

  if ('meta' in res && res.meta?.status === 404) {
    await dispatch(requestGetStoreById('default'));
  }

  dispatch(setSelectedAddress({
    type: deliveryType,
    address: {},
    hash: '',
  }));
  dispatch(setBaseURL(baseURL, host));

  await dispatch(requestGetRetailChain());

  const promises = [
    getTranslations,
    getServicePages,
  ].map((f) => dispatch(f()));

  await Promise.all(promises);
};
