import { createSelector, Selector } from '@reduxjs/toolkit';
import { Coords } from 'src/redux/apiTypes';
import { getCoordsFromDelivery } from 'src/utils/delivery/hasAddressCoords';
import { RootState } from '../../reducers';
import selectSelectedDelivery from './selectSelectedDelivery';

const selectAddressCoords: Selector<RootState, Coords> = createSelector(
  [selectSelectedDelivery],
  (delivery) => getCoordsFromDelivery(delivery),
);

export default selectAddressCoords;
