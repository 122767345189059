import { createSelector, Selector } from '@reduxjs/toolkit';
import { kilosToGrams } from 'src/utils/product/weightConverter';
import { RootState } from '../../reducers';
import { CartItem } from '../general/cartTypes';


export const selectClientCartItems: Selector<RootState, CartItem[]> = (
  state,
) => state.cart.cart;

export const selectClientCartOverweight: Selector<RootState, null | number> = createSelector(
  [
    (state: RootState) => state.addressManagement.delivery,
    (state: RootState) => state.cart.cartPrice['total_weight'],
  ],
  (delivery, totalWeight = 0) => {
    const deliveryType = delivery?.type;

    if(
      deliveryType !== 'nova_poshta' &&
      deliveryType !== 'nova_poshta_fresh'
    ) {
      return null;
    }

    let maxWeightOfDepartment = 0;

    if (deliveryType === 'nova_poshta') {
      maxWeightOfDepartment = delivery.address.nova_poshta.warehouse.max_weight_allowed;
    } else if (deliveryType === 'nova_poshta_fresh') {
      maxWeightOfDepartment = delivery.address.nova_poshta_fresh.warehouse.max_weight_allowed;
    }

    const overweight = totalWeight - kilosToGrams(maxWeightOfDepartment);
    return overweight > 0 ? overweight : null;
  },
);
