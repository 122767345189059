import getStoresByDeliveryType from 'src/ajax/getStoresByDeliveryType';
import getStoresByDeliveryTypeAndCoords from 'src/ajax/getStoresByDeliveryTypeAndCoords';
import { ServerCartDelivery } from 'src/redux/cart/serverCart/types';
import getStoresByCoords from '../../ajax/getStoresByCoords';
import { FetchConfig, Store } from '../../redux/apiTypes';

/**
 *  Checks store in addressData property of passed address and returns new address with correct store.
 *  If passed address is the empty object, returns it.
 */
export async function getCheckedAddress(
  serverCartDelivery: ServerCartDelivery | null,
  config: FetchConfig,
): Promise<{
  serverCartDelivery: ServerCartDelivery | null,
  store: Store | null;
}> {
  let checkedStore = null;

  if (serverCartDelivery) {
    // store is valid for pickup delivery type;
    const localDeliveryType = serverCartDelivery.type;

    if (localDeliveryType === 'pickup') {
      checkedStore = serverCartDelivery.address.pickup.store;
    } else if (localDeliveryType === 'plan') {
      const coords = serverCartDelivery.address.plan.coords;
      [checkedStore] = await getStoresByCoords(coords, config);
    } else if (localDeliveryType === 'nova_poshta_address' || localDeliveryType === 'nova_poshta') {
      [checkedStore] = await getStoresByDeliveryType(localDeliveryType, config);
    } else if (localDeliveryType === 'nova_poshta_fresh') {
      const departmentCoords = serverCartDelivery.address.nova_poshta_fresh.settlement.coords;
      [checkedStore] = await getStoresByDeliveryTypeAndCoords(
        'nova_poshta_fresh',
        departmentCoords,
        config,
      );
    }
  }

  return {
    serverCartDelivery,
    store: checkedStore,
  };
}
