import { ShoppingList } from "src/services/favorites/types";

export const FAVORITE_LISTS = 'favorite_lists';

export const setFavoriteListIdsToSessionStorage = (ids: string[]) => {
  window.sessionStorage.setItem(FAVORITE_LISTS, JSON.stringify(ids));
};

const getFavoriteListIdsFromSessionStorage = (lists: ShoppingList[]) =>{
  const storageListsIds = window.sessionStorage?.getItem(FAVORITE_LISTS);
  const defaultListId = lists.find(l => l.is_default)?.id;
  let listIds: string[] = [];
  const parsedStorageListsIds: string[] = storageListsIds ? JSON.parse(storageListsIds) : [];

  if (parsedStorageListsIds.length > 0) {
    const filteredStorageIds = lists.filter(l => parsedStorageListsIds.includes(l.id));

    if (filteredStorageIds.length > 0) {
      listIds = filteredStorageIds.map(f => f.id);
    } else if (defaultListId) {
      listIds = [defaultListId];
    }
  } else if (defaultListId) {
    listIds = [defaultListId];
  }

  setFavoriteListIdsToSessionStorage(listIds);

  return listIds;
};

export default getFavoriteListIdsFromSessionStorage;
