export const RESET_CATEGORY_DATA = 'RESET_CATEGORY_DATA';
export const START_PREPARE_CATEGORY = 'START_PREPARE_CATEGORY';
export const END_PREPARE_CATEGORY = 'END_PREPARE_CATEGORY';
export const FETCH_CATEGORY_DATA_REQUEST = 'FETCH_CATEGORY_DATA_REQUEST';
export const FETCH_CATEGORY_DATA_SUCCESS = 'FETCH_CATEGORY_DATA_SUCCESS';
export const FETCH_CATEGORY_DATA_ERROR = 'FETCH_CATEGORY_DATA_ERROR';
export const FETCH_LOAD_MORE_CATEGORY_SUCCESS = 'FETCH_LOAD_MORE_CATEGORY_SUCCESS';
export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_ERROR = 'GET_CATEGORY_ERROR';
export const SET_CATEGORY_IS_UNAVAILABLE_FOR_NP = 'SET_CATEGORY_IS_UNAVAILABLE_FOR_NP';
