import { CoordsStrings } from "src/modules/AddressManagement/AddressManagement.types";
import { Coords } from "src/redux/apiTypes";

export const convertCoordsStringToCoordsNumber = (coords?: CoordsStrings): Coords | null => {
  if (!coords) {
    return null;
  }

  return { lat: Number(coords.lat), lng: Number(coords.lng)};
};

export const convertCoordsNumberToCoordsString = (coords: Coords): CoordsStrings => {
  const { lat, lng } = coords;

  return { lat: String(lat), lng: String(lng)};
};

