import { useRouter } from 'next/router';
import { Fragment, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Loader from 'src/components/views/Loader/Loader';
import { HORECA, IS_HORECA, REGISTER_TYPE_B2B } from 'src/redux/constants';
import { useAppSelector } from 'src/redux/hooks';
import { closeModal, openModal } from 'src/redux/modals';
import useToggles from 'src/services/toggles/useToggles';

export interface Props {
  children: ReactNode;
  clientType: string;
}

export default function AppHorecaWrapper(props: Props) {
  const { children, clientType } = props;

  const { HORECA_IS_AVAILABLE } = useToggles();

  const dispatch = useDispatch();
  const router = useRouter();

  const { query } = router;

  const language = useAppSelector((s) => s.app.language);
  const userProfile = useAppSelector((s) => s.userProfile);
  const isLoggedIn = useAppSelector((s) => s.user.isLoggedIn);
  const isLoginChecked = useAppSelector((s) => s.user.isLoginChecked);
  const registerModalHasBeenOpened = useAppSelector((s) => s.modals.loginOrRegistrationModal.isOpen);

  const is_horeca = userProfile.userData[IS_HORECA];
  const needUpdatePolicy = userProfile.userData.need_update_policy;

  useEffect(() => {
    if (isLoggedIn && needUpdatePolicy) {
      dispatch(openModal('confirmTheNewTermsModal'));
    }

    if (
      HORECA_IS_AVAILABLE &&
      query.register === REGISTER_TYPE_B2B &&
      !isLoggedIn &&
      !registerModalHasBeenOpened // so that user can close the pop-up without registering
    ) {
      dispatch(
        openModal('loginOrRegistrationModal', {
          selectedTab: 'registration',
          selectedRegistrationTab: 'horecaUser',
        }),
      );
    } else if (
      HORECA_IS_AVAILABLE &&
      query.register === REGISTER_TYPE_B2B &&
      isLoggedIn &&
      !is_horeca
    ) {
      dispatch(closeModal('loginOrRegistrationModal'));
      router.replace('/[lang]/account/horeca', `/${language}/account/horeca`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    HORECA_IS_AVAILABLE,
    dispatch,
    isLoggedIn,
    is_horeca,
    language,
    needUpdatePolicy,
    query.register,
    registerModalHasBeenOpened,
  ]);

  if (
    ( clientType === HORECA && !isLoginChecked ) ||
    (
      HORECA_IS_AVAILABLE &&
      query.register === REGISTER_TYPE_B2B &&
      (!isLoginChecked || (isLoginChecked && isLoggedIn))
    )
  ) {
    return <Loader />;
  }

  return <Fragment>{children}</Fragment>;
}
