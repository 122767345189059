import { createAction, createReducer } from '@reduxjs/toolkit';
import { AddressDelivery, AddressManagementState } from './types';

export const setAddress = createAction<AddressDelivery>('addressManagement/setAddress');
export const setAddressIsChecked = createAction('AddressManagement/setAddressIsChecked');

export const addressManagementInitialState: AddressManagementState = {
  delivery: {
    type: 'plan',
    address: {},
    hash: '',
  },
  addressIsChecked: false,
};

const addressManagementReducer = createReducer(addressManagementInitialState, (builder) => {
  builder
    .addCase(setAddress, (state, action) => {
      state.delivery = action.payload;
    })
    .addCase(setAddressIsChecked, (state) => {
      state.addressIsChecked = true;
    });
});

export default addressManagementReducer;
