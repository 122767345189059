import { ActionReducerMapBuilder, createAction, Dispatch } from "@reduxjs/toolkit";
import { CategoryProducts, RSAAActionErrorPayload } from "src/redux/apiTypes";
import selectDeliveryType from "src/redux/cart/general/selectDeliveryType";
import { RootState } from "src/redux/reducers";
import { getResponseErrors } from "src/utils/api/getResponseErrors";
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { v4 } from "uuid";
import { createAction as createCacheAction } from '../../apiCacheMiddleware';
import { CategoriesReducersState } from "../categoriesReducers";

const getB2BProductsRequest = createAction('categories/getB2BProducts');
const getB2BProductsSuccess = createAction<CategoryProducts>('categories/getB2BProductsSuccess');
const loadMoreB2BProductsSuccess = createAction<CategoryProducts>('categories/loadMoreB2BProductsSuccess');
const getB2BProductsError = createAction<RSAAActionErrorPayload>('categories/getB2BProductsError');


const createRequestGetB2BProducts = (successActionType: string) => (queryString: string = null) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const storeId = state.storeManagement.store.id;
    const searchString = queryString ? `?${queryString}` : '';
    const deliveryType = selectDeliveryType(state);
    const { fetchConfig } = selectAppConfig(state);
    const { xChain, apiRoot, language, xVersion } = fetchConfig;

    const action = createCacheAction<RootState, CategoryProducts, never>(
      {
        cacheKey: `b2b-${storeId}-${language}-$${searchString}-${deliveryType}`,
        successActionType,
      },
      {
        method: 'GET',
        endpoint: `${apiRoot}/stores/${storeId}/products/filter_by_category/b2b-products/${searchString}`,
        headers: {
          'Accept-Language': language,
          'Content-Type': 'application/json',
          'x-chain': xChain,
          'x-version': xVersion,
          'X-Delivery-Type': deliveryType,
        },
        credentials: 'include',
        types: [
          getB2BProductsRequest.type,
          successActionType,
          getB2BProductsError.type,
        ],
      },
    );

    return dispatch(action);
  };

export const requestGetB2BProducts = createRequestGetB2BProducts(getB2BProductsSuccess.type);
export const requestLoadMoreB2BProducts = createRequestGetB2BProducts(loadMoreB2BProductsSuccess.type);

export function addGetB2BProducts(
  builder: ActionReducerMapBuilder<CategoriesReducersState>,
) {
  builder
    .addCase(getB2BProductsRequest, state => {
      state.b2bProducts.isFetching = true;
    })
    .addCase(getB2BProductsSuccess, (state, action) => {
      state.b2bProducts.data = action.payload;
      state.b2bProducts.dataSignature = v4();
      state.b2bProducts.isFetching = false;
    })
    .addCase(loadMoreB2BProductsSuccess, (state,  action) => {
      state.b2bProducts.isFetching = false;

      if(action.payload) {
        state.b2bProducts.data.results = [...state.b2bProducts.data.results, ...action.payload.results];
      }
    })
    .addCase(getB2BProductsError, (state,  action: { payload: RSAAActionErrorPayload }) => {
      state.b2bProducts.isFetching = false;
      state.b2bProducts.error = getResponseErrors(action.payload);
    });
}
