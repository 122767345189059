import { ActionReducerMapBuilder, createAction } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import selectDeliveryType from "../cart/general/selectDeliveryType";
import { RootState } from "../reducers";
import { SearchInitialState, SearchProductsResponse } from "./types";

const searchProductsRequest = createAction('search/searchProductsRequest');
const searchProductsSuccess = createAction<SearchProductsResponse>('search/searchProductsSuccess');
const searchProductsError = createAction('search/searchProductsError');

const searchProducts = (searchParams: string) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const { id } = state.storeManagement.store;
  const deliveryType = selectDeliveryType(state);
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  return dispatch(createRsaaAction<RootState, SearchProductsResponse, undefined>({
    method: 'GET',
    endpoint: `${apiRoot}/stores/${id}/products/search/${searchParams}`,
    headers: {
      'Accept-Language': language,
      'Content-Type': 'application/json',
      'x-chain': xChain,
      'x-version': xVersion,
      'X-Delivery-Type': deliveryType,
    },
    credentials: 'include',
    types: [
      searchProductsRequest.type,
      searchProductsSuccess.type,
      searchProductsError.type,
    ],
  }));
};

export default searchProducts;

export function addSearchProducts(builder: ActionReducerMapBuilder<SearchInitialState>) {
  builder
    .addCase(searchProductsRequest, (state) => {
      state.isFetching = true;
    })
    .addCase(searchProductsSuccess, (state, action) => {
      const { results, filters, count, categories, count_available } = action.payload;

      state.products = results;
      state.filters = filters;
      state.count = count;
      state.categories = categories;
      state.isFetching = false;
      state.count_available = count_available;
    })
    .addCase(searchProductsError, (state) => {
      state.isFetching = false;
    });
}
