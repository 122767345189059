import { readFileSync } from 'fs';
import { FetchConfig } from 'src/redux/apiTypes';
import { fetchWithMemcached } from '../../utils/ApiCacheClient/fetchWithMemcached';
import { Toggles } from './types';

const togglePrefix = 'ZSW_';

interface AllToggles {
  [toggle: string]: boolean;
}

export async function getToggles(config: FetchConfig): Promise<Toggles> {
  const { apiRoot, xVersion, xChain } = config;

  const response = await fetchWithMemcached<AllToggles>({
    url: `${apiRoot}/features`,
    fetchOptions: {
      headers: {
        'x-chain': xChain,
        'x-version': xVersion,
      },
    },
    cacheKey: `${xChain}-features`,
    ttlSeconds: 600,
  });

  let toggles = filterProjectToggles(response.data, togglePrefix);

  if (process.env.NODE_ENV === 'development') {
    let jsonToggles;

    try {
      jsonToggles = readFileSync('toggles.json').toString();
    } catch (_error) {
      console.warn('\x1b[33m%s\x1b[0m', 'To change toggles locally create `toggles.json` in the root folder. You can use `toggles.sample.json`.');
    }

    const localToggles = jsonToggles && JSON.parse(jsonToggles);

    toggles = { ...toggles, ...localToggles };
  }

  return toggles;
}

export const allProjectsToggles = [
  'COD_WARNING_IS_SHOWN',
  'CARD_ONLINE_WARNING_IS_SHOWN',
  'CONTACTS_SURNAME_REQUIRED',
  'CHAIN_HIDDEN',
];

export default function filterProjectToggles(
  allToggles: AllToggles,
  prefix: string,
): Toggles {
  const filteredToggles = {} as Toggles;

  for (const toggle in allToggles) {
    if (toggle.includes(prefix)) {
      filteredToggles[toggle.replace(prefix, '')] = allToggles[toggle];
    } else if (allProjectsToggles.includes(toggle)) {
      filteredToggles[toggle] = allToggles[toggle];
    }
  }

  return filteredToggles;
}
