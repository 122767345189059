import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import getStoresByCoords from 'src/ajax/getStoresByCoords';
import getStoresByDeliveryType from 'src/ajax/getStoresByDeliveryType';
import setNewDelivery from 'src/components/containers/AddressManagementModal/setNewDelivery';
import useRouterUtils from 'src/hooks/useRouterUtils/useRouterUtils';
import selectSelectedDelivery from 'src/redux/cart/general/selectSelectedDelivery';
import { useAppSelector } from 'src/redux/hooks';
import { openModal } from 'src/redux/modals';
import useDeliveryServices from 'src/swr/useDeliveryServices';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { coordsToNumbers } from '../../helpers/coordsUtils';
import { getDepartments } from '../NovaPoshtaTab/helpers/getDepartments';
import { getStoreData } from '../NovaPoshtaTab/helpers/getStoreData';
import { getDeliveryData } from './helpers/getDeliveryData';
import { MainSiteNovaPoshtaAddressDelivery, MainSiteNovaPoshtaDelivery, MainSitePickupDelivery, MainSitePlanDelivery } from './types';

interface Query {
  delivery: string;
}

export default function DeliveryUrlWatcher() {
  const { router, cleanUrlQuery } = useRouterUtils<Query>();
  const dispatch = useDispatch();

  const { fetchConfig } = useAppConfig();

  const delivery = useAppSelector(selectSelectedDelivery);
  const cartInitialized = useAppSelector((s) => !!s.app.cartType);
  const language = useAppSelector((s) => s.app.language);
  const { deliveryServices, deliveryServicesAreFetching } = useDeliveryServices(language);
  const dataIsReady = !deliveryServicesAreFetching && cartInitialized;
  const canSetDeliveryFromUrl = dataIsReady && !delivery;
  const encodedDeliveryData = router.query.delivery;
  const canCleanUrl = dataIsReady;

  useEffect(() => {
    if (canCleanUrl) {
      cleanUrlQuery();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canCleanUrl]);

  useEffect(() => {
    if (canSetDeliveryFromUrl) {
      const urlDelivery = getDeliveryData(encodedDeliveryData);

      if (urlDelivery?.type === 'plan') {
        handleSetPlanDelivery(urlDelivery);
      }

      if (urlDelivery?.type === 'pickup') {
        handleSetPickupDelivery(urlDelivery);
      }

      // с корп. сайта не прилетает nova_poshta_fresh
      // мы приводим его в тип nova_poshta
      if (urlDelivery?.type === 'nova_poshta') {
        handleSetNovaPoshtaDelivery(urlDelivery);
      }

      if (urlDelivery?.type === 'nova_poshta_address') {
        handleSetNovaPoshtaAddressDelivery(urlDelivery);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSetDeliveryFromUrl]);

  const handleSetPlanDelivery = async (delivery: MainSitePlanDelivery) => {
    const stores = await getStoresByCoords(delivery.geocodedAddress.coords, fetchConfig);

    if (stores.length) {
      dispatch(setNewDelivery({
        type: 'plan',
        address: {
          plan: {
            city: delivery.geocodedAddress.city,
            street: delivery.geocodedAddress.street,
            building: delivery.geocodedAddress.building,
            coords: delivery.geocodedAddress.coords,
            delivery_service_id: delivery.deliveryServiceId,
          },
        },
        hash: '',
      }, stores[0]));
    } else {

      dispatch(
        openModal('addressModal', {
          deliveryType: 'plan',
          coords: delivery.geocodedAddress.coords,
          skipInitialSetup: true,
        }),
      );
    }
  };

  const handleSetPickupDelivery = (delivery: MainSitePickupDelivery) => {
    const deliveryService = deliveryServices.find((d) => d.id === delivery.deliveryServiceId);

    dispatch(
      openModal('addressModal', {
        deliveryType: 'pickup',
        coords: deliveryService?.coords || undefined,
      }),
    );
  };

  const handleSetNovaPoshtaDelivery = async (delivery: MainSiteNovaPoshtaDelivery) => {
    const departments = await getDepartments(delivery.settlement.id, fetchConfig);
    const department = departments.find((d) => d.id === delivery.warehouse.id);

    if (department) {
      const { store, deliveryType } = await getStoreData(department.coords, fetchConfig);

      dispatch(setNewDelivery({
        type: deliveryType,
        address: {
          [deliveryType]: {
            settlement: delivery.settlement,
            warehouse: department,
          },
        },
        hash: '',
      }, store));
    } else {

      dispatch(
        openModal('addressModal', {
          deliveryType: 'nova_poshta',
          coords: coordsToNumbers(delivery.settlement.coords),
          skipInitialSetup: true,
        }),
      );
    }
  };

  const handleSetNovaPoshtaAddressDelivery = async (delivery: MainSiteNovaPoshtaAddressDelivery) => {
    const showAmModal = () => {
      dispatch(
        openModal('addressModal', {
          deliveryType: 'nova_poshta_address',
          coords: coordsToNumbers(delivery.settlement.coords),
        }),
      );
    };

    if (delivery.settlement.address_delivery_not_allowed) {
      showAmModal();
    } else {
      const stores = await getStoresByDeliveryType('nova_poshta_address', fetchConfig);

      if (stores.length > 0) {
        dispatch(setNewDelivery({
          type: 'nova_poshta_address',
          address: {
            nova_poshta_address: {
              address: {
                settlement: delivery.settlement,
                building: delivery.building,
                street: delivery.street,
              },
            },
          },
          hash: '',
        }, stores[0]));
      } else {
        showAmModal();
      }
    }
  };

  return null;
}
