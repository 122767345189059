import { ActionReducerMapBuilder, createAction } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import selectDeliveryType from "../cart/general/selectDeliveryType";
import { RootState } from "../reducers";
import { SearchInitialState, SearchProductsResponse } from "./types";

const searchLoadMoreProductsRequest = createAction('search/searchLoadMoreProductsRequest');
const searchLoadMoreProductsSuccess = createAction<SearchProductsResponse>('search/searchLoadMoreProductsSuccess');
const searchLoadMoreProductsError = createAction('search/searchLoadMoreProductsError');

const searchLoadMoreProducts = (searchParams: string) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const { id } = state.storeManagement.store;
  const deliveryType = selectDeliveryType(state);
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, apiRoot, language, xVersion } = fetchConfig;

  return dispatch(createRsaaAction<RootState, SearchProductsResponse, undefined>({
    method: 'GET',
    endpoint: `${apiRoot}/stores/${id}/products/search/${searchParams}`,
    headers: {
      'Accept-Language': language,
      'Content-Type': 'application/json',
      'x-chain': xChain,
      'x-version': xVersion,
      'X-Delivery-Type': deliveryType,
    },
    credentials: 'include',
    types: [
      searchLoadMoreProductsRequest.type,
      searchLoadMoreProductsSuccess.type,
      searchLoadMoreProductsError.type,
    ],
  }));
};

export default searchLoadMoreProducts;

export function addLoadMoreSearchProducts(builder: ActionReducerMapBuilder<SearchInitialState>) {
  builder
    .addCase(searchLoadMoreProductsRequest, (state) => {
      state.isFetching = true;
    })
    .addCase(searchLoadMoreProductsSuccess, (state, action) => {
      state.products = [
        ...state.products,
        ...action.payload.results,
      ];
      state.isFetching = false;
    })
    .addCase(searchLoadMoreProductsError, (state) => {
      state.isFetching = false;
    });
}
