import { Dispatch } from 'redux';
import { setAddressInLocalStorage } from 'src/utils/delivery/addressLocalStorage';
import setDeliveryTypeCookie from '../../utils/delivery/setDeliveryTypeCookie';
import { ServerCartDelivery } from '../cart/serverCart/types';
import { setAddress } from './addressManagement';

const setSelectedAddress = (address: ServerCartDelivery) => (dispatch: Dispatch) => {
  setDeliveryTypeCookie(address.type);
  setAddressInLocalStorage(address);
  dispatch(setAddress(address));
};

export default setSelectedAddress;
