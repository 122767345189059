import { AddressDelivery } from "src/redux/addressManagement/types";
import { CartItem } from "src/redux/cart/general/cartTypes";
import { DEFAULT_DELIVERY_TYPE } from "src/redux/constants";
import { RequestPostScheduleDeliveryBody } from "src/swr/useScheduleDelivery/scheduleDeliveryTypes";

const getScheduleDeliveryRequestBodyForClientCart = (delivery: AddressDelivery, cartItems: CartItem[]) => {
  const deliveryType = delivery?.type || DEFAULT_DELIVERY_TYPE;

  const requestBody: RequestPostScheduleDeliveryBody = {
    delivery_method: deliveryType,
    cart_items: cartItems
      .filter(i => i.isAvailable && i.amount > 0)
      .map(({ean, amount}) => ({ean, amount})),
      slot_types: ['open', 'item_specific'],
  };

  // this condition has no case for np_fresh because we should send coords for np_fresh
  if(deliveryType === 'nova_poshta') {
    requestBody.settlement_id = delivery.address.nova_poshta.settlement.id;
  } else if (deliveryType === 'nova_poshta_address') {
    requestBody.settlement_id = delivery.address.nova_poshta_address.address.settlement.id;
  } else if (deliveryType === 'nova_poshta_fresh') {
    const { lat, lng } = delivery.address.nova_poshta_fresh.settlement.coords;

    requestBody.coords = `${ lat },${ lng }`;
  } else if (deliveryType === 'plan') {
    const { lat, lng } = delivery.address.plan.coords;

    requestBody.coords = `${ lat },${ lng }`;
  } else if (deliveryType === 'pickup') {
    const { lat, lng } = delivery.address.pickup.store.address.coords;

    requestBody.coords = `${ lat },${ lng }`;
  }

  return requestBody;
};

export default getScheduleDeliveryRequestBodyForClientCart;
