import getConfig from 'next/config';
import { DeliveryType } from './apiTypes';

const { publicRuntimeConfig } = getConfig();

export const SENTRY_DSN = publicRuntimeConfig.NEXT_PUBLIC_SENTRY_DSN;
export const ENVIRONMENT_NAME = publicRuntimeConfig.NEXT_PUBLIC_ENVIRONMENT_NAME;
export const BUILD_NUMBER = publicRuntimeConfig.NEXT_PUBLIC_BUILD_NUMBER;
export const APP_INSTANCE_ID = publicRuntimeConfig.APP_INSTANCE_ID;

/** @deprecated Don't import, use useAppConfig or appConfigSelector inside redux */
export const LATEST_API_VERSION = '60';

/** @deprecated remove */
export const LOGIN = 'LOGIN';
export const CHECK_LOGIN = 'CHECK_LOGIN';

export const REGISTER_SIGNUP = 'REGISTER_SIGNUP';
export const REGISTER_CONFIRM = 'REGISTER_CONFIRM';
export const REGISTER_HORECA = 'REGISTER_HORECA';
export const RESET_CART_PRICE_ERROR = 'RESET_CART_PRICE_ERROR';

export const CONFIRM_RECOVERY_REQUEST = 'CONFIRM_RECOVERY_REQUEST';
export const CONFIRM_RECOVERY_SUCCESS = 'CONFIRM_RECOVERY_SUCCESS';
export const CONFIRM_RECOVERY_ERROR = 'CONFIRM_RECOVERY_ERROR';
export const CREATE_PASSWORD_REQUEST = 'CREATE_PASSWORD_REQUEST';
export const CREATE_PASSWORD_SUCCESS = 'CREATE_PASSWORD_SUCCESS';
export const CREATE_PASSWORD_ERROR = 'CREATE_PASSWORD_ERROR';

export const REQUEST = '_REQUEST';
export const SUCCESS = '_SUCCESS';
export const ERROR = '_ERROR';
export const RESET_ERROR = '_RESET_ERROR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RECOVERY_PASSWORD = 'RECOVERY_PASSWORD';

export const GENERAL_ERROR = 'GENERAL_ERROR';
export const FIRST_NAME = 'FIRST_NAME';
export const LAST_NAME = 'LAST_NAME';
export const USER_EMAIL = 'USER_EMAIL';
export const USER_LOGIN_PHONE = 'USER_LOGIN_PHONE';
export const USER_LOGIN_EMAIL = 'USER_LOGIN_EMAIL';
export const USER_PHONE_LIST = 'USER_PHONE_LIST';
export const USER_BIRTH_DATE = 'USER_BIRTH_DATE';
export const USER_CAN_EDIT_EMAIL = 'USER_CAN_EDIT_EMAIL';
export const IS_HORECA = 'IS_HORECA';
export const SUBSCRIBE_TO_MARKETING = 'SUBSCRIBE_TO_MARKETING';

export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const SET_CONFIRMED_AGE = 'SET_CONFIRMED_AGE';

export const DEFAULT_PLAN_TYPE = 'apartment';

export const MEASURE_PCS = 'pcs';
export const MEASURE_KG = 'kg';

export const IS_SERVER = typeof window === 'undefined';

export const NOT_FOUND = 'NOT_FOUND';

export const RESTORE_ORDER_INFO = 'RESTORE_ORDER_INFO';

export const HORECA = 'horeca';

export const REGISTER_TYPE_B2B = 'b2b';

export const CLIENT_TYPE = 'clientType';
export const DELIVERY_COMMENT_MAX_LENGTH = 1000;

export const ADMITAD_COOKIE_NAME = 'admitad_uid';
export const SALESDOUBLER_COOKIE_NAME = 'sd_uid';

export const GAME_NOTIFY = 'game_notification_is_opened';


// Service pages
export const OFERTA = 'oferta';

export const FILTER_PRICE_SEPARATOR = '-';

export const HEADER_PROMOTION_CLOSED_COOKIE = 'header-promotion-closed';

export const SERVICE_PAGE_PREFIX = 'alpha-';

export const SEGMENT_OF_CATEGORIES = 'categories';
export const NOT_MULTI_FILTER_PARAMS = [
  'sort',
  'page',
  'q',
  'per_page',
  'admitad_uid',
  'aff_sub',
  'gclid',
  'utm_medium',
  'utm_source',
  'utm_campaign',
  'utm_id',
  'utm_term',
  'utm_content',
  'variant',
  'price',
  'delivery',
  'api',
];

export const MAP_MIN_ZOOM = 2;
export const MAP_MAX_ZOOM = 18;

export const DEFAULT_DELIVERY_TYPE: DeliveryType = 'plan';
